<template>
    <form-modal
        ref="formModal"
        :v="$v.modalBrand"
        :title="i18nTitleModal"
        :in-editing="true"
        :handle-close="handleCancel">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-form-row>
                    <b-col md="12">
                        <b-form-group
                            :label="i18nName"
                            label-for="modal-name"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_name')"
                            class="required label-pdv"
                        >
                            <b-form-input
                                id="modal-name"
                                v-model.trim="$v.modalBrand.name.$model"
                                class="input-pdv-blue"
                                name="name"
                                aria-describedby="input-1-live-feedback"
                                type="text"
                                :state="validateField('name', 'modalBrand')"
                                :disabled="submitLoading"
                                autofocus
                                autocomplete="off"
                                @input="clearResponseError('name', 'modalBrand')"
                                @blur="$v.modalBrand.name.$touch"
                            />
                        </b-form-group>
                    </b-col>
                </b-form-row>
            </form>
        </template>
        <template v-slot:footer>
            <save-button
                v-if="isBrandCreated"
                ref="okButton"
                :busy="submitLoading"
                @onClick="handleOk"
            />
            <add-button
                v-else
                ref="okButton"
                :busy="submitLoading"
                @onClick="handleOk"
            />
            <cancel-label-button
                ref="cancelButton"
                :busy="submitLoading"
                @onClick="handleCancel"
            />
        </template>
    </form-modal>
</template>

<script>
import FormModal from '@components/FormModal'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import validationMixin from '@src/mixins/validation'
import swalFeedback from '@src/mixins/swalFeedback'
import BrandService from '@src/services/BrandService'
import CancelLabelButton from '@/src/components/CancelLabelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import cloneObject from '@utils/clone-object'

const i18nCommon = 'COMMON'
const i18nKey = 'BRANDS'

export default {
    components: {
        FormModal,
        CancelLabelButton,
        AddButton,
        SaveButton
    },
    mixins: [validationMixin, swalFeedback],
    data() {
        return {
            i18nCommon,
            i18nKey,
            modalBrand: {},
            submitLoading: false,
        }
    },
    validations: {
        modalBrand: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },
        },
    },
    computed: {
        isBrandCreated() {
            return this.modalBrand.id > 0
        },
        modalOperation() {
            return this.isBrandCreated ? 'edited' : 'created'
        },
        i18nTitleModal() {
            if (this.isBrandCreated) {
                return `${this.getI18n(i18nCommon, 'edit')} ${this.i18nBrand}`
            }
            else {
                return this.getI18n(i18nKey, 'BUTTONS.new_brand')
            }
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nBrand() {
            return this.getI18n(i18nKey, 'TITLES.brand')
        },
    },
    methods: {
        showModal(data) {
            this.submitLoading = false

            if (data) {
                this.modalBrand = cloneObject(data);
            } else {
                this.modalBrand = {
                    id: 0,
                    name: '',
                };
            }

            this.$refs.formModal.show()
            this.resetValidation()
        },

        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
        },

        handleCancel() {
            this.resetValidation()
            this.$refs.formModal.hide()
        },

        handleOk() {
            this.$v.modalBrand.$touch()
            if (!this.$v.modalBrand.$invalid && !this.submitLoading) {
                this.submitLoading = true
                this.handleSubmit()
            }
        },

        async handleSubmit() {
            const operation = this.isBrandCreated
                ? BrandService.update(this.modalBrand.id, this.modalBrand)
                : BrandService.create(this.modalBrand)

            const response = await operation
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.$refs.formModal.hide()
                this.resetValidation()
                this.positiveFeedback(this.i18nBrand, this.modalOperation, '3')
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            }

            this.submitLoading = false
        },
    },
}
</script>
