<script>
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue';
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import BrandService from '@src/services/BrandService'
import Layout from '@layouts/main.vue'
import brandModal from '@views/brands/brandModal'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import Pagination from '@src/mixins/pagination.vue'
import swalFeedback from '@src/mixins/swalFeedback'
import validationMixin from '@src/mixins/validation.vue'
import AddLabelButton from '@/src/components/AddLabelButton.vue'
import ImportButton from '@/src/components/ImportButton.vue'
import ExportButton from '@/src/components/ExportButton.vue'
import EditTableButton from '@/src/components/EditTableButton.vue'
import DeleteTableButton from '@/src/components/DeleteTableButton.vue'

import FilterBar from '@src/components/FilterBar'
import importModal from '@views/importJob/importJobModal'

import { debounce } from 'lodash'

const i18nCommon = 'COMMON'
const i18nKey = 'BRANDS'

export default {
    metaInfo() {
        return {
            title: this.i18nBrand(2),
        }
    },
    components: {
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        Layout,
        brandModal,
        ConfirmationFormModal,
        FeedbackModal,
        FilterBar,
        importModal,
        AddLabelButton,
        ImportButton,
        ExportButton,
        EditTableButton,
        DeleteTableButton,
    },
    mixins: [Pagination, swalFeedback, validationMixin],
    data() {
        return {
            sortOrder: [
                {
                    field: 'name',
                    direction: 'asc',
                },
            ],
            i18nCommon,
            i18nKey,
            additionalParameters: {},
            perPage: 10,
            fields: [
                {
                    name: 'name',
                    title: this.getI18nModified({
                        prefix: 'COMMON',
                        suffix: 'name',
                        modifier: 1,
                    }),
                    sortField: 'name',
                },
                {
                    name: 'actions',
                    title: '',
                    visible: this.$can('DELETE', 'BRAND'),
                },
            ],
            filterText: '',
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: 'PRODUCTS',
                        suffix: 'TITLES.product',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.brand',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nNewBrand() {
            return this.getI18n(i18nKey, 'BUTTONS.new_brand')
        },
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters.search = ''
            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },
    methods: {
        fetch(url, params) {
            return BrandService.fetchVuetable(url, params)
        },
        createAdditionalParameters() {
            this.additionalParameters.search = ''
            if (this.filterText && this.filterText.length > 0) {
                this.additionalParameters.search = `name:${this.filterText}`
            }
            return this.additionalParameters
        },
        showImportModal() {
            this.$nextTick(() => this.$refs.importModal.showModal())
        },
        i18nBrand(modifier) {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'TITLES.brand',
                modifier,
            })
        },
        showModal(data) {
            this.$nextTick(() => this.$refs.brandModal.showModal(data))
        },
        openModalToCreate() {
            this.showModal()
        },
        openModalToEdit(data) {
            this.showModal(data)
        },
        reloadTable() {
            this.$refs.vuetable.reload()
        },
        refreshTable() {
            this.$refs.vuetable.refresh()
        },
        handleDelete(id) {
            this.$refs.confirmationFormModal.showModal(false, this.deleteBrand, id)
        },
        async deleteBrand(deleteRecord, id) {
            if (!deleteRecord) {
                return;
            }

            if (id > 0) {
                const response = await BrandService.delete(id)
                    .then((response) => response)
                    .catch((error) => error)

                const statusCode = response.status.toString()

                if (statusCode.charAt(0) === '2') {
                    this.refreshTable()
                    this.positiveFeedback(this.i18nBrand(1), 'deleted', 3)
                } else if (statusCode === '417') {
                    this.$refs.feedbackModal.showModal(statusCode, response.data.message)
                } else if (statusCode === '422') {
                    this.modalResponseErrors = response.data.errors
                } else {
                    this.$refs.feedbackModal.showModal(statusCode, response.data.message)
                }
            }
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText"></filter-bar>
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <add-label-button
                            v-if="$can('CREATE', 'BRAND')"
                            :title="i18nNewBrand"
                            @onClick="openModalToCreate"
                        />
                        <import-button
                            v-if="$can('CREATE', 'IMPORTJOB')"
                            @onClick="showImportModal"
                        />
                        <export-button
                            v-if="$can('CREATE', 'EXPORTJOB')"
                            type="brand"
                            :create-parameters="createAdditionalParameters"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nBrand(2)" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :display-selection-records-per-page="true"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template slot="actions" slot-scope="props">
                            <div class="table-button-container">
                                <edit-table-button v-if="$can('UPDATE', 'BRAND')" @onClick="openModalToEdit(props.rowData)" />
                                <delete-table-button v-if="$can('DELETE', 'BRAND')" class="ml-1" @onClick="handleDelete(props.rowData.id)" />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <brand-modal
            ref="brandModal"
            @refresh="reloadTable"
        />
        <import-modal
            ref="importModal"
            :csv-type="'brand'"
            :title="
                getI18nModified({
                    prefix: 'BRANDS',
                    suffix: 'TITLES.brand',
                    modifier: 2,
                })
            "
        />
        <confirmation-form-modal
            ref="confirmationFormModal"
            :title="i18nBrand(1)"
            :operation="'deleted'"
        />
        <feedback-modal
            ref="feedbackModal"
            :title="i18nBrand(1)"
        />
    </Layout>
</template>
